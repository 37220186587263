import React from 'react';

import SEO from '../components/seo';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>
      There&#39;s nothing here{' '}
      <span role="img" aria-label="spooky">
        👻
      </span>
      .
    </p>
  </>
);

export default NotFoundPage;
